import { FormattedMessage } from 'react-intl';

import { faArrowRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { isTemporaryHidden, useRouteUtils } from '@trustyou/shared';
import { Button, Stack, StyledFontAwesomeIcon } from '@trustyou/ui';

import { ReviewsLastUpdate } from './reviews-last-update';
import { Views } from './views';

import { useFetchReviewsInfo } from '../hooks';

export function InboxHeader() {
  const { navigateWithBase } = useRouteUtils();
  const { data: reviewsInfo } = useFetchReviewsInfo();

  const lastProducedAt = reviewsInfo?.last_produced_at ?? '';
  const hasReviews = lastProducedAt !== undefined;

  return (
    <Stack spacing={3} sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Stack spacing={3}>
        <ReviewsLastUpdate hasReviews={hasReviews} lastProducedAt={lastProducedAt} />
        <Views hasReviews={hasReviews} />
      </Stack>
      {!isTemporaryHidden('CXP-customers-inbox-reports') && (
        <Stack sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            endIcon={<StyledFontAwesomeIcon icon={faArrowRight} />}
            onClick={() => navigateWithBase('reports/new-report?fromInbox')}
          >
            <FormattedMessage id="inbox.create-report" defaultMessage="Create report" />
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
