import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import Grid from '@mui/material/Unstable_Grid2';
import { useQueryClient } from '@tanstack/react-query';
import {
  faArrowLeft,
  faCheck,
  faEnvelope,
  faLanguage,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faChevronRight } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { useEntity, useLanguageStore } from '@trustyou/shared';
import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
  snackbar,
} from '@trustyou/ui';

import { IconButtonWithTooltip } from '../../components/icon-button-with-tooltip';
import { Response } from '../../components/response';
import { ReviewDeletion } from '../../components/review-deletion';
import { ReviewItem } from '../../components/review-item';
import { SurveyModeration } from '../../components/survey-moderation/survey-moderation';
import { INBOX_TOOLBAR_HEIGHT } from '../../constants';
import {
  updateReviewStatusById,
  useLeaveDialog,
  useReview,
  useSaveResponse,
  useSetReviewReadStatus,
} from '../../hooks';
import useSetReviewsRespondedStatusPermission from '../../hooks/permissions/use-set-reviews-responded-status-permission';
import useSetReviewsStatusPermission from '../../hooks/permissions/use-set-reviews-status-permission';
import useTranslatePermission from '../../hooks/permissions/use-translate-permission';
import { useStore } from '../../store/store';
import {
  isDeletionConfirmed,
  isDeletionPendingOrRequested,
  useSurroundingReviewIds,
} from '../../utils/review';

// TODO: Refactor this component to encapsulate different logic into custom hooks, reducing the number of states and `useEffect`.
export function Review() {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { reviewId = '' } = useParams();
  const { accessTokenPayload } = useOidcAccessToken();
  const saveResponse = useSaveResponse();

  const updateEntityData = useStore.use.updateEntityData();
  const isResponseFormDirty = useStore.use.isResponseFormDirty();
  const updateHasExistingResponse = useStore.use.updateHasExistingResponse();
  const { locale } = useLanguageStore();

  const setReviewStatus = useSetReviewReadStatus();
  const isSetReviewsStatusAllowed = useSetReviewsStatusPermission();
  const isSetReviewsRespondedStatusAllowed = useSetReviewsRespondedStatusPermission();
  const isTranslateAllowed = useTranslatePermission();

  const [shouldFetchTranslation, setShouldFetchTranslation] = useState(false);
  const [shouldDisplayTranslation, setShouldDisplayTranslation] = useState(false);

  const review = useReview({ reviewId, shouldFetchTranslation });
  useEffect(() => {
    if (review.data && review.data.meta.status === 'unread' && isSetReviewsStatusAllowed) {
      setReviewStatus.mutate(
        { status: 'read', reviews: [review.data.review.id] },
        {
          onSuccess: () => {
            updateReviewStatusById(queryClient, review.data?.review.id, 'read');
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [review.data]);

  const entity = useEntity(review.data?.meta?.entity_id ?? '');
  useEffect(() => {
    if (entity.data) {
      updateEntityData(entity.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.data]);

  const isLoading = entity.isLoading || review.isLoading;

  const { renderLeaveDialog, canContinue } = useLeaveDialog({
    showDialog: isResponseFormDirty,
  });
  const { prevReviewId, nextReviewId } = useSurroundingReviewIds(reviewId);

  const goBack = async () => {
    const rirListPath = location.pathname.slice(0, location.pathname.lastIndexOf('/') + 1);
    if (await canContinue()) {
      navigate(rirListPath);
    }
  };

  const markAsUnread = () => {
    if (!review.data) return;
    setReviewStatus.mutate(
      {
        status: 'unread',
        reviews: [review.data.id],
      },
      {
        onSuccess: () => {
          updateReviewStatusById(queryClient, review.data.id, 'unread');
          goBack();
        },
      }
    );
  };

  if (!review.data) {
    return null;
  }

  const handleMarkAsResponded = () => {
    if (!review.data.id) return;

    saveResponse.mutate(
      {
        reviewId,
        payload: {
          // TODO: SIN-390 remove this line to stop sending the author to the backend
          author: accessTokenPayload.name,
          text: '[empty text]',
        },
      },
      {
        onSuccess: () => {
          snackbar.success(
            intl.formatMessage({
              id: 'inbox.review-marked-responded',
              defaultMessage: 'Review marked responded',
            })
          );
          // updateReviewRoot({
          //   ...review.data,
          //   meta: { ...review.data.meta, status: 'responded' },
          // });
          updateHasExistingResponse(true);
          updateReviewStatusById(queryClient, review.data.review.id, 'responded');
        },
      }
    );
  };

  const navigateTo = async (position: 'previous' | 'next') => {
    const destination = { previous: prevReviewId, next: nextReviewId };
    const destinationReviewId = destination[position] ?? '';
    if (await canContinue()) navigate(location.pathname.replace(reviewId, destinationReviewId));
  };

  const handleTranslate = () => {
    const hasStoredTranslation = Boolean(review.data.review.translations?.[locale]);
    if (!hasStoredTranslation) {
      setShouldFetchTranslation(!shouldFetchTranslation);
    }
    setShouldDisplayTranslation(!shouldDisplayTranslation);
  };

  const getTranslateTooltip = () => {
    if (!isTranslateAllowed) {
      return (
        <FormattedMessage
          id="inbox.user.roles.read.only.inbox.alert"
          defaultMessage="Your role is read only"
        />
      );
    } else if (shouldDisplayTranslation) {
      return (
        <FormattedMessage id="inbox.review.action.show-original" defaultMessage="Show original" />
      );
    }
    return <FormattedMessage id="inbox.review.action.translate" defaultMessage="Translate" />;
  };

  const isOnlineReview = !review.data.survey;
  const shouldShowTrashIconButton = isOnlineReview;
  const shouldShowFlagIconButton = !isOnlineReview;

  const isDeletionProcessStartedOrFinished =
    isDeletionPendingOrRequested(review.data.deletion) || isDeletionConfirmed(review.data.deletion);
  const isModerationProcessStarted = ['requested', 'propagating'].includes(
    review.data.moderation?.status ?? ''
  );
  const isModerationProcessApproved = ['approved'].includes(review.data.moderation?.status ?? '');

  const isMarkAsUnreadDisabled =
    isLoading ||
    !isSetReviewsStatusAllowed ||
    isDeletionProcessStartedOrFinished ||
    isModerationProcessStarted ||
    isModerationProcessApproved;

  const isMarkAsRespondedDisabled =
    !!review.data.response ||
    !review.data.review?.respondable ||
    !isSetReviewsRespondedStatusAllowed ||
    isDeletionProcessStartedOrFinished ||
    isModerationProcessStarted ||
    isModerationProcessApproved;

  return (
    <Paper
      elevation={0}
      sx={{
        margin: -3,
        height: (theme) => `calc(100vh - ${theme.spacing(19)})`,
      }}
    >
      <Grid container height="100%">
        <Grid xs={12}>
          <Stack
            direction="row"
            sx={{
              height: INBOX_TOOLBAR_HEIGHT,
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1,
            }}
          >
            <Stack direction="row" gap={1}>
              <IconButtonWithTooltip
                onClick={goBack}
                icon={faArrowLeft}
                tooltip={
                  <FormattedMessage id="inbox.back-to-inbox" defaultMessage="Back to Inbox" />
                }
                color="primary"
              />
              {!isLoading && ['read', 'unread'].includes(review.data.meta.status ?? '') && (
                <IconButtonWithTooltip
                  data-gtm-class="review_action_on_review"
                  data-gtm-id="mark_as_unread"
                  onClick={markAsUnread}
                  icon={faEnvelope}
                  tooltip={
                    isSetReviewsStatusAllowed ? (
                      <FormattedMessage id="inbox.mark-as-unread" defaultMessage="Mark as unread" />
                    ) : (
                      <FormattedMessage
                        id="inbox.user.roles.read.only.inbox.alert"
                        defaultMessage="Your role is read only"
                      />
                    )
                  }
                  disabled={isMarkAsUnreadDisabled}
                  color="primary"
                />
              )}
              {!isLoading && review.data && (
                <>
                  <IconButtonWithTooltip
                    data-gtm-class="review_action_on_review"
                    data-gtm-id="mark_as_responded"
                    disabled={isMarkAsRespondedDisabled}
                    onClick={handleMarkAsResponded}
                    icon={faCheck}
                    tooltip={
                      isSetReviewsRespondedStatusAllowed ? (
                        <FormattedMessage
                          id="inbox.mark-responded"
                          defaultMessage="Mark as responded"
                        />
                      ) : (
                        <FormattedMessage
                          id="inbox.user.roles.read.only.inbox.alert"
                          defaultMessage="Your role is read only"
                        />
                      )
                    }
                    color="primary"
                  />
                  <IconButtonWithTooltip
                    disabled={isLoading || !isTranslateAllowed}
                    data-gtm-class="review_action_on_review"
                    data-gtm-id="translate"
                    data-gtm-param-1={locale}
                    onClick={handleTranslate}
                    icon={faLanguage}
                    tooltip={getTranslateTooltip()}
                    color="primary"
                  />
                  {shouldShowTrashIconButton && <ReviewDeletion />}
                  {shouldShowFlagIconButton && <SurveyModeration />}
                </>
              )}
            </Stack>
            <Stack direction="row" gap={3}>
              <Button
                startIcon={
                  <StyledFontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 'inherit' }} />
                }
                disabled={!prevReviewId}
                onClick={() => navigateTo('previous')}
                data-gtm-class="inbox_navigation_button"
                data-gtm-id="previous"
              >
                <FormattedMessage id="inbox.review.navigation.previous" defaultMessage="Previous" />
              </Button>
              <Button
                endIcon={
                  <StyledFontAwesomeIcon icon={faChevronRight} style={{ fontSize: 'inherit' }} />
                }
                disabled={!nextReviewId}
                onClick={() => navigateTo('next')}
                data-gtm-class="inbox_navigation_button"
                data-gtm-id="next"
              >
                <FormattedMessage id="inbox.review.navigation.next" defaultMessage="Next" />
              </Button>
            </Stack>
          </Stack>
          <Divider />
        </Grid>
        {isLoading ? (
          <Stack
            // height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress sx={{ margin: 'auto' }} />
          </Stack>
        ) : review.data ? (
          <>
            <Grid
              xs={6}
              height="100%"
              sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}
            >
              <ReviewItem shouldDisplayTranslation={shouldDisplayTranslation} />
            </Grid>
            <Grid xs={6} height="100%">
              <Response />
            </Grid>
          </>
        ) : (
          <Typography width={300} textAlign="center" margin="auto" marginTop={0}>
            <FormattedMessage
              defaultMessage="Something didn't quite go as planned. Please, give the page a quick refresh. If that doesn't work, no worries — just come back and try again a bit later"
              id="inbox.review.loadError"
            />
          </Typography>
        )}
      </Grid>
      {renderLeaveDialog()}
    </Paper>
  );
}
