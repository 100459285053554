import { FormattedMessage } from 'react-intl';

import { Typography } from '@trustyou/ui';
import dayjs from 'dayjs';

export type ReviewsLastUpdateProps = {
  hasReviews: boolean;
  lastProducedAt: string;
};

export function ReviewsLastUpdate({ hasReviews, lastProducedAt }: ReviewsLastUpdateProps) {
  return (
    <Typography variant="caption" color="text.secondary">
      {hasReviews ? (
        <FormattedMessage
          id="inbox.reviews-last-update"
          defaultMessage="Last update: {lastUpdate} UTC"
          values={{
            lastUpdate: dayjs(lastProducedAt).format('DD.MM.YYYY, HH:mm'),
          }}
        />
      ) : (
        <FormattedMessage id="inbox.no-reviews-present" defaultMessage="No reviews present" />
      )}
    </Typography>
  );
}
