import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  useEmailSettings,
  useEmailSettingsForSurveyModeration,
  useUpdateEmailSettings,
  useUpdateEmailSettingsForSurveyModeration,
} from '@trustyou/inbox';
import { commonFields, isTemporaryHidden } from '@trustyou/shared';
import {
  Button,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  Switch,
  Typography,
  snackbar,
} from '@trustyou/ui';

import { EntitySelectorForEmailNotifications } from './entity-selector-for-email-notifications';
import { KeywordsTextarea, ScoreSelect, SourceSelect } from './form-fields';

import type {
  EmailNotificationSettingsIn_Input,
  EmailSurveyNotificationSettingsIn,
} from '../../../client';
import useGetSettingsPermission from '../../../hooks/permissions/use-get-settings-permission';
import useModerationRevisionPermission from '../../../hooks/permissions/use-moderation-revision-permission';
import useSetSettingsPermission from '../../../hooks/permissions/use-set-settings-permission';
import { getSearchTerms, parseSearchTerms } from '../../../utils/keyword-search';

export type NotificationsSettingsFormType = {
  entity_ids: EmailNotificationSettingsIn_Input['filters']['entity_ids'];
  daily: {
    enabled: EmailNotificationSettingsIn_Input['enabled'];
    schedule_type: 'daily';
    schedule_hour: EmailNotificationSettingsIn_Input['schedule_hour'];
    filters: {
      score: EmailNotificationSettingsIn_Input['filters']['score'];
      source: EmailNotificationSettingsIn_Input['filters']['source'];
      search_terms: string;
    };
  };
  realtime: {
    enabled: boolean;
    filters: {
      score: EmailNotificationSettingsIn_Input['filters']['score'];
      // TODO: Add survey filter type from openapi when available from backend
      // survey: string[];
    };
  };
  survey_moderation: EmailSurveyNotificationSettingsIn;
};

const NOTIFICATION_SETTINGS_DEFAULT_VALUES: NotificationsSettingsFormType = {
  entity_ids: [],
  daily: {
    enabled: true,
    schedule_type: 'daily',
    schedule_hour: 2,
    filters: {
      score: ['no_score', 'low', 'medium', 'high'],
      source: ['all'],
      search_terms: '',
    },
  },
  realtime: {
    enabled: true,
    filters: {
      score: ['no_score', 'low', 'medium', 'high'],
    },
  },
  survey_moderation: {
    enabled: true,
  },
};

export function EmailSettingsForm() {
  const intl = useIntl();
  const isAllowedToGetSettings = useGetSettingsPermission();
  const isAllowedToSetSettings = useSetSettingsPermission();
  const hasModerationPermission = useModerationRevisionPermission();

  const emailSettings = useEmailSettings();
  const updateEmailSettings = useUpdateEmailSettings();

  const emailSettingsForSurveyModeration = useEmailSettingsForSurveyModeration();
  const updateEmailSettingsSurveyModeration = useUpdateEmailSettingsForSurveyModeration();

  const isPending = emailSettings.isPending || emailSettingsForSurveyModeration.isPending;

  const dailyEmailSettings = emailSettings.data?.find(
    ({ schedule_type }) => schedule_type === 'daily'
  );
  const realtimeSettings = emailSettings.data?.find(
    // @ts-expect-error 'realtime' is not defined as schedule_type in openapi
    ({ schedule_type }) => schedule_type === 'realtime'
  );

  const methods = useForm<NotificationsSettingsFormType>({
    values: {
      entity_ids: dailyEmailSettings?.filters?.entity_ids ?? [],
      daily: {
        enabled:
          (dailyEmailSettings?.enabled ?? NOTIFICATION_SETTINGS_DEFAULT_VALUES.daily.enabled) &&
          (dailyEmailSettings?.filters?.entity_ids ?? [])?.length > 0,
        schedule_type: NOTIFICATION_SETTINGS_DEFAULT_VALUES.daily.schedule_type,
        schedule_hour:
          dailyEmailSettings?.schedule_hour ??
          NOTIFICATION_SETTINGS_DEFAULT_VALUES.daily.schedule_hour,
        filters: {
          score:
            dailyEmailSettings?.filters?.score ??
            NOTIFICATION_SETTINGS_DEFAULT_VALUES.daily.filters.score,
          source:
            dailyEmailSettings?.filters?.source ??
            NOTIFICATION_SETTINGS_DEFAULT_VALUES.daily.filters.source,
          search_terms: parseSearchTerms(dailyEmailSettings?.filters?.search_terms ?? []),
        },
      },
      realtime: {
        enabled: true,
        filters: {
          score:
            realtimeSettings?.filters?.score ??
            NOTIFICATION_SETTINGS_DEFAULT_VALUES.realtime.filters.score,
          // TODO: Add survey filter type from openapi when available from backend
          // survey: realtimeSettings?.filters?.survey ?? []
        },
      },
      survey_moderation: {
        enabled:
          emailSettingsForSurveyModeration.data?.enabled ??
          NOTIFICATION_SETTINGS_DEFAULT_VALUES.survey_moderation.enabled,
      },
    },
    resetOptions: {
      // Keep dirty fields unchanged, but update defaultValues.
      keepDirtyValues: true,
    },
  });
  const { handleSubmit, control, watch } = methods;
  const isDailyEmailEnabled = watch('daily.enabled');
  const isSurveyRealtimeNotificationsEnabled = watch('realtime.enabled');

  const onSubmit = (form: NotificationsSettingsFormType) => {
    if (form.entity_ids?.length === 0 && (form.daily.enabled || form.survey_moderation.enabled)) {
      snackbar.warning(
        intl.formatMessage({
          id: 'inbox.settings.notifications.entities.select-at-least-one-entity',
          defaultMessage: 'Select at least one entity',
        })
      );
      return;
    }
    if (!isAllowedToSetSettings) {
      snackbar.warning('Permissions are needed to change Inbox settings');
      return;
    }
    const dailySettingsPromise = updateEmailSettings.mutateAsync({
      enabled: form.daily.enabled,
      schedule_type: form.daily.schedule_type,
      schedule_hour: form.daily.schedule_hour,
      filters: {
        entity_ids: form.entity_ids,
        score: form.daily.filters.score,
        source: form.daily.filters.source,
        search_terms: getSearchTerms(form.daily.filters.search_terms),
      },
    });
    const surveyModerationSettingsPromise = updateEmailSettingsSurveyModeration.mutateAsync(
      form.survey_moderation,
      {
        onSuccess: () => {
          snackbar.success(intl.formatMessage(commonFields.changesSaved));
        },
      }
    );
    Promise.all([dailySettingsPromise, surveyModerationSettingsPromise]).then(() => {
      snackbar.success(intl.formatMessage(commonFields.changesSaved));
    });
  };

  if (!isAllowedToGetSettings) {
    snackbar.warning('Permissions are needed to see Inbox settings');
    return;
  }

  if (isPending) {
    return <LinearProgress />;
  }

  return (
    <FormProvider {...methods}>
      <Typography variant="h6" sx={{ marginBlockEnd: 2 }}>
        <FormattedMessage
          id="inbox.settings.notifications.title"
          defaultMessage="Inbox notifications"
        />
      </Typography>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
        <EntitySelectorForEmailNotifications />
        <FormGroup
          sx={{
            gap: 2,
            ...(isDailyEmailEnabled && { paddingBlockEnd: 3 }),
            alignItems: 'start',
            maxWidth: (theme) => theme.spacing(63),
          }}
        >
          <Controller
            control={control}
            name="daily.enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label={intl.formatMessage({
                  id: 'inbox.settings.notifications.daily.label',
                  defaultMessage: 'Receive a daily email summary of new reviews',
                })}
              />
            )}
          />
          <Stack
            spacing={3}
            sx={{
              marginLeft: 6,
              ...(!isDailyEmailEnabled && { height: 0, visibility: 'hidden', overflow: 'hidden' }),
            }}
          >
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
              <ScoreSelect />
              <SourceSelect />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body2" sx={{ paddingTop: 1 }}>
                <FormattedMessage
                  id="inbox.settings.notifications.keywords-statement"
                  defaultMessage="Include only reviews that contain the following keywords:"
                />
              </Typography>
              <KeywordsTextarea />
            </Stack>
          </Stack>
        </FormGroup>
        {!isTemporaryHidden('CXP-customers-settings-survey-realtime-notifications') && (
          <FormGroup
            sx={{
              gap: 2,
              ...(isSurveyRealtimeNotificationsEnabled && { paddingBlockEnd: 3 }),
              alignItems: 'start',
              maxWidth: (theme) => theme.spacing(63),
            }}
          >
            <Controller
              control={control}
              name="realtime.enabled"
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} checked={field.value} />}
                  label={intl.formatMessage({
                    id: 'inbox.settings.notifications.realtime.label',
                    defaultMessage:
                      'Receive an email summary of all new survey reviews from the past hour',
                  })}
                />
              )}
            />
            <Stack
              spacing={3}
              sx={{
                marginLeft: 6,
                ...(!isSurveyRealtimeNotificationsEnabled && {
                  height: 0,
                  visibility: 'hidden',
                  overflow: 'hidden',
                }),
              }}
            >
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                <ScoreSelect />
                {/* TODO: */}
                {/* <SurveySelect /> */}
              </Stack>
            </Stack>
          </FormGroup>
        )}
        {hasModerationPermission && (
          <FormGroup>
            <Controller
              control={control}
              name="survey_moderation.enabled"
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} checked={field.value} />}
                  label={intl.formatMessage({
                    id: 'inbox.settings.notifications.survey-moderation.label',
                    defaultMessage: 'Receive survey review moderation requests via email',
                  })}
                />
              )}
            />
          </FormGroup>
        )}
        <Button
          type="submit"
          variant="contained"
          data-testid="save-changes-inbox-notifications"
          sx={{ alignSelf: 'end', marginBlockStart: 5 }}
        >
          <FormattedMessage {...commonFields.saveChanges} />
        </Button>
      </Stack>
    </FormProvider>
  );
}
